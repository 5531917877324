<template>
  <div class="main">
    <div class="user content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item> Candidates</a-breadcrumb-item>
        <a-breadcrumb-item><router-link :to="'/client/candidate_group'+loginAs"> Group</router-link></a-breadcrumb-item>
        <a-breadcrumb-item> {{group?.name}}</a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <h3>{{group?.name}}</h3>
          <div class="add">
            <a-button type="primary" @click="candidateDrawer"> <UserAddOutlined /> Add Candidate </a-button>
          </div>
        </a-col>
      </a-row>
      <a-row class="groupcourse-Lists">
        <div class="body">
          <a-table :columns="candidateColumns" :data-source="assignedCandidates" class="ant-table-striped"
            :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            :pagination="{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
            }"
            rowKey="_id"
          >
            <template #price="{ text: price }">
              <span>
                <a-tag color="green">£ {{ price }} </a-tag>
              </span>
            </template>
            <template #action="{ record, index }">
              <div class="actionblock">
                <img src="../../../assets/images/deleteicon.svg" @click="confirmDeleteCandidate(record._id, index)" />
              </div>
            </template>
          </a-table>
        </div>
      </a-row>
    </div>
    <div class="candidate-list">
      <a-drawer title="Candidate List" placement="right" :closable="true" v-model:visible="drawVisible" @ok="closeCandidateListModal" class="candidatelistDrawer" :maskClosable="false" :destroyOnClose="true" :width="300">
        <a-checkbox v-model:checked="selectAllCandidate" :indeterminate="indeterminate" @change="onCheckAllChange">
          All Candidates
        </a-checkbox>
        <a-checkbox-group v-model:value="selectedCandidates" :options="availableCandidates"/>
        <div class="footersec">
          <a-button type="primary" @click="assignCandidate" :loading="loading">Assign</a-button>
        </div>
      </a-drawer>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, createVNode, ref, reactive, toRefs, onMounted } from 'vue'
import { HomeOutlined, UserAddOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { notification, Modal } from 'ant-design-vue'
import { useRoute } from 'vue-router'

import ICandidate from '../../../interface/profile'
import groupService from '../../../services/candidateGroup'
import commonServices from '../../../services/common'

interface Group {
  _id?: string;
  name: string;
  description: string;
  client?: string;
}

export default defineComponent({
  components: {
    HomeOutlined,
    UserAddOutlined
  },
  setup () {
    const route = useRoute()
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const groupId = ref<string>(route.params.id as string)
    const group = ref<Group>()
    const assignedCandidates = ref<Array<any>>([])
    const loading = ref<boolean>(false)
    const drawVisible = ref<boolean>(false)
    const availableCandidates = ref([])
    const unassignedCandidates = ref<Array<ICandidate>>([])
    const candidateColumns = [
      {
        title: 'First Name',
        dataIndex: 'first_name',
        onFilter: (value, record) => record.first_name.indexOf(value) === 0,
        sorter: (a, b) => a.first_name.length - b.first_name.length,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Last Name',
        dataIndex: 'first_name',
        onFilter: (value, record) => record.last_name.indexOf(value) === 0,
        sorter: (a, b) => a.last_name.length - b.last_name.length,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Email',
        dataIndex: 'email'
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        slots: { customRender: 'action' }
      }
    ]
    const candidateDrawer = () => {
      drawVisible.value = true
    }
    const closeCandidateListModal = () => {
      drawVisible.value = false
    }
    const state = reactive({
      indeterminate: false,
      selectAllCandidate: false,
      selectedCandidates: []
    })
    const onCheckAllChange = (e: any) => {
      const aCourse = []
      for (const course of availableCandidates.value) {
        aCourse.push(course.value)
      }
      Object.assign(state, {
        selectedCandidates: e.target.checked ? aCourse : [],
        indeterminate: false
      })
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const getGroupDetails = async () => {
      try {
        const responce = await groupService.getGroupDetails(groupId.value)
        group.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getGroupCandidates = async () => {
      try {
        const responce = await groupService.getGroupCandidates(groupId.value)
        assignedCandidates.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getUnassignedCandidates = async () => {
      try {
        availableCandidates.value = []
        const profile = commonServices.getCurrentProfile()
        const responce = await groupService.getUnassignedCandidates(groupId.value, profile._id)
        unassignedCandidates.value = responce.data
        for (const profile of unassignedCandidates.value) {
          availableCandidates.value.push({ label: profile.first_name + ' ' + profile.last_name, value: profile._id })
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const assignCandidate = async () => {
      try {
        loading.value = true
        const assign = []
        for (const profileId of state.selectedCandidates) {
          assign.push({ enabled: true, candidate: profileId, group: groupId.value })
        }
        await groupService.assignCandidates(assign)
        await notify('Success', 'Candidate assigned successfully', 'success')
        closeCandidateListModal()
        await getGroupCandidates()
        loading.value = false
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const removeCandidate = async (id, index) => {
      try {
        await groupService.deleteGroupCandidate(id)
        assignedCandidates.value.splice(index, 1)
        await notify('Success', 'Candidate removed from the group', 'success')
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const confirmDeleteCandidate = async (id, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(CloseCircleOutlined),
        content: createVNode('div', {}, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          await removeCandidate(id, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const visible = ref<boolean>(false)
    onMounted(() => {
      getGroupDetails()
      getGroupCandidates()
      getUnassignedCandidates()
    })
    return {
      candidateColumns,
      confirmDeleteCandidate,
      visible,
      notify,
      group,
      loading,
      assignedCandidates,
      candidateDrawer,
      closeCandidateListModal,
      drawVisible,
      ...toRefs(state),
      onCheckAllChange,
      unassignedCandidates,
      assignCandidate,
      availableCandidates,
      loginAs
    }
  }
})
</script>

<style lang="scss">
.user {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .ant-col{
          display: flex;
    justify-content: space-between;
    }
    h3 {
      font-size: 16px;
      text-transform: capitalize;
      color: #646464;
      font-family: "TT Norms Pro Medium";
      margin: 0;
    }
    .add {
      float: right;
      .ant-btn {
        background-color: #7EAF1A;
        border-color: #7EAF1A;
        border-radius: 5px;
        font-family: "TT Norms Pro Medium";
        a {
          color: #ffffff;
        }
      }
    }
  }
  .groupcourse-Lists {
    padding: 10px;
    .body {
      background: #ffffff;
      box-shadow: 0px 5px 10px #00000003;
      border-radius: 8px;
      padding: 15px;
      width: 100%;
      font-family: "TT Norms Pro Medium";
      .ant-table {
        color: #646464;
        .ant-table-thead {
          tr {
            th {
              background: #e6e6e6;
              color: #646464;
              &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
              }
              &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }
          }
        }
        .ant-table-tbody {
          tr {
            td {
              border-bottom: none;
              &:nth-child(2) {
                color: #7EAF1A;
              }
            }
          }
          .actionblock{
            img{
              cursor: pointer;
            }
          }
        }
      }
      .ant-pagination {
        .ant-pagination-options {
          // display: none;
        }
        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          border-radius: 50%;
          background-color: #ededed;
          border: none;
        }
        .ant-pagination-item {
          background-color: #ededed;
          margin-right: 0px;
          border: none;
          font-family: "TT Norms Pro Regular";
        }
        li:nth-child(2) {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
        li:nth-last-child(3n) {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
        .ant-pagination-next {
          margin-left: 8px;
        }
        .ant-pagination-item-active {
          background-color: #ededed;
          a {
            background: #6f64f8;
            color: #fff;
            border-radius: 50%;
          }
        }
      }
      .totalentries {
        font-family: "TT Norms Pro Regular";
        text-align: left;
        margin: -40px 0 0 0;
        color: #646464;
        p {
          margin: 0;
        }
      }
    }
  }
}
.candidatelistDrawer {
  .ant-checkbox-group-item {
    width: 100%;
  }
  .footersec {
    margin: 10px auto;
    display: table;
    .ant-btn {
      background-color: #7EAF1A;
      border-color: #7EAF1A;
      border-radius: 5px;
      font-family: "TT Norms Pro Medium";
      a {
        color: #ffffff;
      }
    }
  }
}
</style>
<style scoped>
.ant-table-striped :deep(.table-striped) {
  background-color: #f7fafc;
}
</style>
