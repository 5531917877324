
import { defineComponent, createVNode, ref, reactive, toRefs, onMounted } from 'vue'
import { HomeOutlined, UserAddOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { notification, Modal } from 'ant-design-vue'
import { useRoute } from 'vue-router'

import ICandidate from '../../../interface/profile'
import groupService from '../../../services/candidateGroup'
import commonServices from '../../../services/common'

interface Group {
  _id?: string;
  name: string;
  description: string;
  client?: string;
}

export default defineComponent({
  components: {
    HomeOutlined,
    UserAddOutlined
  },
  setup () {
    const route = useRoute()
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const groupId = ref<string>(route.params.id as string)
    const group = ref<Group>()
    const assignedCandidates = ref<Array<any>>([])
    const loading = ref<boolean>(false)
    const drawVisible = ref<boolean>(false)
    const availableCandidates = ref([])
    const unassignedCandidates = ref<Array<ICandidate>>([])
    const candidateColumns = [
      {
        title: 'First Name',
        dataIndex: 'first_name',
        onFilter: (value, record) => record.first_name.indexOf(value) === 0,
        sorter: (a, b) => a.first_name.length - b.first_name.length,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Last Name',
        dataIndex: 'first_name',
        onFilter: (value, record) => record.last_name.indexOf(value) === 0,
        sorter: (a, b) => a.last_name.length - b.last_name.length,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Email',
        dataIndex: 'email'
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        slots: { customRender: 'action' }
      }
    ]
    const candidateDrawer = () => {
      drawVisible.value = true
    }
    const closeCandidateListModal = () => {
      drawVisible.value = false
    }
    const state = reactive({
      indeterminate: false,
      selectAllCandidate: false,
      selectedCandidates: []
    })
    const onCheckAllChange = (e: any) => {
      const aCourse = []
      for (const course of availableCandidates.value) {
        aCourse.push(course.value)
      }
      Object.assign(state, {
        selectedCandidates: e.target.checked ? aCourse : [],
        indeterminate: false
      })
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const getGroupDetails = async () => {
      try {
        const responce = await groupService.getGroupDetails(groupId.value)
        group.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getGroupCandidates = async () => {
      try {
        const responce = await groupService.getGroupCandidates(groupId.value)
        assignedCandidates.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getUnassignedCandidates = async () => {
      try {
        availableCandidates.value = []
        const profile = commonServices.getCurrentProfile()
        const responce = await groupService.getUnassignedCandidates(groupId.value, profile._id)
        unassignedCandidates.value = responce.data
        for (const profile of unassignedCandidates.value) {
          availableCandidates.value.push({ label: profile.first_name + ' ' + profile.last_name, value: profile._id })
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const assignCandidate = async () => {
      try {
        loading.value = true
        const assign = []
        for (const profileId of state.selectedCandidates) {
          assign.push({ enabled: true, candidate: profileId, group: groupId.value })
        }
        await groupService.assignCandidates(assign)
        await notify('Success', 'Candidate assigned successfully', 'success')
        closeCandidateListModal()
        await getGroupCandidates()
        loading.value = false
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const removeCandidate = async (id, index) => {
      try {
        await groupService.deleteGroupCandidate(id)
        assignedCandidates.value.splice(index, 1)
        await notify('Success', 'Candidate removed from the group', 'success')
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const confirmDeleteCandidate = async (id, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(CloseCircleOutlined),
        content: createVNode('div', {}, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          await removeCandidate(id, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const visible = ref<boolean>(false)
    onMounted(() => {
      getGroupDetails()
      getGroupCandidates()
      getUnassignedCandidates()
    })
    return {
      candidateColumns,
      confirmDeleteCandidate,
      visible,
      notify,
      group,
      loading,
      assignedCandidates,
      candidateDrawer,
      closeCandidateListModal,
      drawVisible,
      ...toRefs(state),
      onCheckAllChange,
      unassignedCandidates,
      assignCandidate,
      availableCandidates,
      loginAs
    }
  }
})
